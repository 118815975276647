.header {
  position: relative;
  z-index: 100;
}

.menu-wrapper {
  position: fixed;
  width: 100%;
  background-color: var(--black);
  z-index: 10;

  /*  .menu-is-open & {
    @media (--media-md) {
      padding-right: 15px;
    }
  }*/
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2rem 1.5rem;

  @media (--media-md) {
    padding: 2.5rem 3rem 2rem;
  }
}

.menu__content {
  display: flex;
  align-items: center;
}

.menu__item.menu__item--language {
  display: none;
  @media (--media-lg) {
    display: block;
  }
  .menu-is-open & {
    user-focus: none;
    pointer-events: none;
  }
}

.menu__item.menu__item--cta {
  display: none;
  @media (--media-lg) {
    display: block;
  }

  z-index: 140;
}

.menu-overlay .menu__item.menu__item--cta {
  display: block;
  margin: 2rem 0.5rem;
  @media (--media-lg) {
    display: none;
  }
}

.menu-overlay .menu__ctas {
  display: flex;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.menu__item--donation {
  margin-right: var(--general-margin);
}

/*
.menu__item--large {
  @media (--media-lg) {
    margin-right: 6rem;
  }
}
 */

.header-logo {
  svg {
    width: 12rem;
    @media (--media-md) {
      width: auto;
    }
  }
}

.menu-language {
  margin-right: 2rem;

  &.menu-language--mobile {
    margin-top: 4rem;
    transform: translateY(40px);
    opacity: 0;
    .menu-is-open & {
      animation: fade-in-nav-title both cubic-bezier(0.165, 0.84, 0.44, 1) 0.35s
        0.5s;
    }

    @media (--media-lg) {
      display: none;
    }
  }
}

.menu-language__item {
  display: inline-block;

  a {
    color: var(--white);
    text-decoration: none;
    padding: 0 0.15rem;

    .menu-language--mobile & {
      color: var(--black);
      font-size: 24px;
    }

    &:focus-visible {
      outline: 0.15rem dashed var(--light-gray);
      color: var(--light-gray);
    }

    &:focus:not(:focus-visible) {
      outline: 0;
    }
  }
}

.menu-toggle__trigger {
  display: flex;
  align-items: center;
  z-index: 130;

  &:focus-visible {
    outline: 0.15rem dashed var(--light-gray);
    color: var(--light-gray);
  }

  &:focus:not(:focus-visible) {
    outline: 0;
  }
}

.menu-toggle__title--wrapper {
  position: relative;
  width: 6rem;
  display: none;

  @media (--media-md) {
    display: block;
    height: 1.5rem;
  }

  @media (--media-lg) {
    height: 1.75rem;
  }
}

.menu-toggle__title {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--white);

  .menu-is-open & {
    color: var(--black);
  }
}

.menu-toggle__title--opened {
  opacity: 0;
  transition:
    opacity 0.35s,
    color 0.35s;

  .menu-is-open & {
    opacity: 1;
    transition:
      opacity 0.35s 0.55s,
      color 0.35s 0.55s;
  }
}

.menu-toggle__title--closed {
  opacity: 1;
  transition:
    opacity 0.35s 0.15s,
    color 0.35s 0.15s;

  .menu-is-open & {
    opacity: 0;
    transition:
      opacity 0.35s 0.35s,
      color 0.35s 0.35s;
  }
}

.menu-toggle {
  position: relative;
  width: 35px;
  height: 35px;
  padding: 0.35rem 0;
  line-height: 25px;
  transition: color 0.2s;

  @media (--media-md) {
    margin-right: 1rem;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--white);
    border-radius: 50%;
    width: 35px;
    height: 35px;

    .menu-is-open & {
      background-color: var(--black);
    }
  }

  span {
    position: relative;
    display: block;
    width: 20px;
    height: 2px;
    margin: 4px auto;
    top: 0;
    z-index: 1;
    backface-visibility: hidden;
    background: var(--black);
    transition:
      top 0.3s 0.3s,
      transform 0.3s;

    .menu-is-open & {
      background-color: var(--white);
    }
  }

  .menu-is-open & span:first-child {
    transform: rotate(45deg) scaleX(1);
    transition-duration: 0s, 0s;
    animation: menu-open-top 0.75s;
    top: 7px;
  }

  .menu-is-open & span:nth-child(2) {
    opacity: 0;
  }

  .menu-is-open & span:last-child {
    animation: menu-open-bottom 0.75s;
    top: -5px;

    transform: rotate(-45deg) scaleX(1);
    transition-duration: 0s, 0s;
  }
}

@keyframes menu-open-bottom {
  0% {
    opacity: 1;
    top: 0;
    left: 0;
    transform: rotate(0) scaleX(1);
    transform-origin: right center;
  }
  50% {
    opacity: 1;
    top: 0;
    left: 0;
    transform: rotate(0) scaleX(0);
  }
  50.1% {
    opacity: 0;
    top: 0;
    left: 0;
    transform: rotate(0) scaleX(0);
  }
  51%,
  55% {
    opacity: 1;
    top: -12px;
    left: -3px;
    transform: rotate(-45deg) scaleX(0);
  }
  100% {
    opacity: 1;
    top: -12px;
    left: -3px;
    transform: rotate(-45deg) scaleX(1);
    transform-origin: right center;
  }
}

@keyframes menu-open-top {
  0% {
    opacity: 1;
    top: 0;
    left: 0;
    transform: rotate(0) scaleX(1);
    transform-origin: left center;
  }
  30% {
    opacity: 1;
    top: 0;
    left: 0;
    transform: rotate(0) scaleX(0);
  }
  30.1% {
    opacity: 0;
    top: 0;
    left: 0;
    transform: rotate(0) scaleX(0);
  }
  31% {
    opacity: 1;
    top: 0;
    left: 3px;
    transform: rotate(45deg) scaleX(0);
  }
  100%,
  80% {
    opacity: 1;
    top: 0;
    left: 3px;
    transform: rotate(45deg) scaleX(1);
    transform-origin: left center;
  }
}

/* Overlay */

.menu-overlay__content {
  position: absolute;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  top: 25px;
  left: 0;
  border-top-right-radius: var(--radius-md);
  border-top-left-radius: var(--radius-md);
  padding: 80px 0px 50px 20px;
  background-color: var(--white);
  transform: translateY(100%);
  opacity: 0;
  will-change: auto;
  user-focus: none;
  pointer-events: none;
  z-index: 90;
  transition:
    transform 0.55s,
    opacity 0.35s 0.55s;
  @media (--media-md) {
    transition:
      transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1),
      opacity 0.35s 0.55s;
  }

  .menu-is-open & {
    overflow-y: auto;
    transition:
      transform 0.55s,
      opacity 0.35s;
    transform: translateY(0);
    opacity: 1;
    user-focus: all;
    pointer-events: all;
    @media (--media-md) {
      transition:
        transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1),
        opacity 0.35s;
    }
  }

  @media (--media-md) {
    border-top-right-radius: var(--radius-lg);
    border-top-left-radius: var(--radius-lg);
    padding: 15vh 130px 50px 240px;
  }

  @media (--media-lg) {
    border-top-right-radius: var(--radius-xl);
    border-top-left-radius: var(--radius-xl);
  }
}

.menu-overlay__navigation-container {
  address {
    transform: translateY(40px);
    opacity: 0;

    .menu-is-open & {
      @media (--media-sm-only) {
        animation: fade-in-nav-title both ease 0.35s 0.35s !important;
      }

      animation: fade-in-nav-title both cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }
}

.main-navigation__link-item {
  text-decoration: none;
  opacity: 0;
  display: block;
  position: relative;
  transform: translateY(40px);
  margin-bottom: 0.5rem;

  @media (--media-md) {
    margin-bottom: 1.25rem;
  }

  .menu-is-open & {
    animation: fade-in-nav-title both cubic-bezier(0.165, 0.84, 0.44, 1);

    @media (--media-sm-only) {
      animation: fade-in-nav-title both ease 0.35s 0.35s !important;
    }
  }
}

.main-navigation {
  margin-bottom: 2rem;

  @media (--media-md) {
    margin-bottom: 4rem;
  }
  li.submenu {
    margin-left: 40px;
  }
}

@keyframes fade-in-nav-title {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Hero */

.hero {
  display: flex;
  min-height: 100vh;
  background-color: var(--black);
  border-bottom-right-radius: var(--radius-md);
  border-bottom-left-radius: var(--radius-md);
  z-index: 0;

  @media (--media-md) {
    border-bottom-right-radius: var(--radius-lg);
    border-bottom-left-radius: var(--radius-lg);
  }

  @media (--media-lg) {
    border-bottom-right-radius: var(--radius-xl);
    border-bottom-left-radius: var(--radius-xl);
  }
}

.hero-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 20vh;
  padding: 2rem 2rem 0;

  h1 {
    z-index: 1;

    @media (--media-lg) {
      width: 90%;
    }
  }

  @media (--media-md) {
    align-items: baseline;
    padding: 2.5rem 3rem 2rem;
    width: 90vw;
  }
}

.scroll-icon {
  position: absolute;
  bottom: 6rem;
  display: none;

  @media (--media-md) {
    display: block;
  }
}

.hero__image {
  position: relative;
  height: 100%;
  width: 100%;
  bottom: 0;
  right: 0;
  z-index: 0;

  @media (--media-md) {
    position: absolute;
    height: 50vh;
    width: 50vw;
  }

  @media (--media-lg) {
    width: 35vw;
  }
}
