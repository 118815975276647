.form {
  .widget--radioselect > div,
  .widget--checkboxselectmultiple > div {
    list-style: none;
    padding: 0;
    margin-top: 0;
  }

  .errors {
    display: flex;
    align-items: center;
    line-height: 1.3;

    padding: 0.5rem;
    border: 1px solid;
    border-radius: 999px;
    margin-bottom: 1rem;

    color: var(--red-dark);

    i {
      font-size: 2em;
    }
  }

  .errors--warning {
    color: var(--warning);
  }

  .errors__text {
    margin-left: 0.5rem;
  }

  .is-invalid {
    input,
    select,
    textarea {
      border-color: var(--red-dark);
    }
  }

  .field__errors {
    color: var(--red-dark);
  }

  &[data-textarea-autoresize] {
    textarea {
      display: block;
      overflow: hidden;
      resize: none;
    }
  }
}

.form-item {
  &:hover {
    .field-help {
      color: var(--light-gray);
    }
  }
}

.form-item--full {
  grid-column: 1 / -1;
}

.field {
  position: relative;
  padding: 0;
  border: 0;
  margin-bottom: var(--general-margin);

  label {
    display: block;
    margin-bottom: 0.5rem;

    &.required {
      &:after {
        content: "*";
      }
    }
  }

  div.cke_textarea_inline {
    min-height: 300px;
  }

  input,
  textarea,
  div.cke_textarea_inline {
    display: block;
    width: 100%;
    border: 1px solid var(--black);
    border-radius: var(--radius-xs);
    padding: 0.25rem 1.5rem;

    &.--filled {
      border: 1px solid var(--ui-green);
      color: var(--ui-green);

      &:hover {
        border: 1px solid var(--ui-green);
      }
    }

    &::placeholder,
    & {
      color: var(--black);
      font-size: responsive 21px 24px;
      line-height: 1.5;
      lower-font-range: 540px;
      upper-font-range: 1440px;
    }

    &:focus {
      box-shadow: 3px 2px 11px -5px var(--black);
      outline: none;
    }

    &:hover {
      border: 1px solid var(--light-gray);

      &::placeholder {
        color: var(--light-gray);
      }
    }

    &[name="code"] {
      width: auto;
    }
  }
}

.field-error {
  display: none;
  color: var(--ui-red);
}

.field--error {
  input {
    &::placeholder,
    & {
      color: var(--ui-red);
      border-color: var(--ui-red);
    }
  }

  .field-error {
    display: block;
  }
}

.field-help {
  margin-bottom: 0.5rem;
}

.widget--select {
  select {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.5rem 2.5rem;
    border: 1px solid var(--black);
    border-radius: var(--radius-sm);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("icons/arrow-down-black.svg") 96% / 3% no-repeat #fff;
  }
}

.widget--radioselect {
  > div {
    > li {
      display: flex;
      justify-content: flex-start;
    }
    label {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0.5rem 2.5rem;
      border: 1px solid var(--black);
      border-radius: var(--radius-sm);
      cursor: pointer;

      &.--filled {
        border: 1px solid var(--ui-green);
        color: var(--ui-green);

        &:after {
          content: "";
          position: absolute;
          right: 1rem;
          width: 0.85rem;
          height: 0.85rem;
          transform: translateY(20%);
          background-image: url("icons/check.svg");
          background-size: contain;
          background-repeat: no-repeat;
        }

        .field__input {
          background: var(--ui-green);
          border: 1px solid var(--ui-green);
        }
      }
    }
    input {
      height: 1px;
      width: 1px;
      opacity: 0;
    }
  }

  .field__input {
    display: flex;
    position: absolute;
    left: 0.5rem;
    order: 1;
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid var(--gray);
    border-radius: 50%;
    box-sizing: border-box;
    padding: 0.3rem;
  }
}

.widget--checkboxinput {
  position: relative;
  display: flex;
  align-items: center;

  label {
    cursor: pointer;
  }

  .field__input {
    display: flex;
    position: absolute;
    left: 0.5rem;
    order: 1;
    width: 1.75rem;
    height: 1.75rem;
    padding: 0.3rem;
    border: 1px solid var(--gray);
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
  }

  &.--filled {
    label {
      color: var(--ui-green);
    }

    .field__input {
      border-color: var(--ui-green);

      &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 60%;
        width: 0.85rem;
        height: 0.85rem;
        transform: translate(-50%, -50%);
        background-image: url("icons/check.svg");
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  input {
    height: 1px;
    width: 1px;
    opacity: 0;
  }

  label {
    width: 100%;
    padding: 0.25rem 0;
    margin-bottom: 0;
    margin-left: 2.5rem;
    color: var(--gray);
    order: 2;
  }

  .field__errors {
    position: absolute;
    top: 1.5rem;
  }
}

.widget--hiddeninput {
  display: none;
}

/* RaiseNow styles */

:root {
  --tamaro-primary-color: #0a0a0f !important;
  --tamaro-primary-color__hover: #0a0a0f !important;
  --tamaro-primary-bg-color: #f5f04b !important;
}

.tamaro-widget {
  font-family: "Atak", sans-serif !important;

  h2.title {
    display: flex !important;
    align-items: center !important;

    span {
      font-size: 18px !important;

      @media (--media-md) {
        font-size: 24px !important;
      }
    }
  }

  .position {
    width: 40px !important;
    height: 40px !important;
    background-color: var(--tamaro-primary-bg-color) !important;
    color: var(--black) !important;
  }

  .label-radio-block {
    &.selected {
      border-color: var(--tamaro-primary-bg-color) !important;
    }
  }
}

.group {
  border: 1px solid var(--black);
  border-radius: var(--radius-xs);
  margin-bottom: 1rem;

  transition:
    border-radius var(--general-transition-speed),
    background-color var(--general-transition-speed);

  &[open],
  &:hover {
    border-radius: 0;
  }
}

.group__title {
  cursor: pointer;
  padding: 0.5rem 1rem;
  scroll-margin-top: 10rem;
}

.group__content {
  padding: 1rem;
}

.field__help {
  display: block;
  padding-top: 0.5rem;
  margin-top: calc(-1 * var(--general-margin));
  margin-bottom: var(--general-margin);
}
